import { Router } from '@angular/router';
import { SysconfigService } from './sysconfig.service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
//required to install: npm install --save ts-md5
import {Md5} from 'ts-md5/dist/md5';
import { WithdrawalResponse } from '../interface/definition';
import { catchError,retry,timeout,finalize,switchMap, reduce } from 'rxjs/operators';
import { of } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';

export const ENDPOINT = {
  url:'/en/withdraw'
};


@Injectable({
  providedIn: 'root'
})
export class WithdrawService {


  /*
  ============================================
  1. Define Property Settings
  ============================================
  */

  errorMsg: string;
  httpTimeout: number;
  httpRetry: number;
  debugMode: boolean;
  isLoading: boolean;

  constructor(
    private http: HttpClient,
    private sysconf: SysconfigService,
    private loading: LoadingController,
    private loadingDetails: LoadingController,
    private alert: AlertController,
    private router: Router
  ) { }



  async showLoader(){
    /*
    https://www.positronx.io/ionic-loading-controller-tutorial-with-ion-loading-example/
    https://newbedev.com/ionic-4-loading-controller-dismiss-is-called-before-present-which-will-keep-spinner-without-dismissing
    */
    return await this.loading.create({
      keyboardClose:false,
      translucent: true,
      cssClass: 'loading',
      message:'Sending Request, Please Wait...'
    }).then(loadingUI=>{
      //this.loadingUIGlobal=loadingUI;
       loadingUI.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          loadingUI.dismiss().then(() => console.log('abort presenting'));
        }
      });

    });

  }

  async hideLoader(){
    this.isLoading = false;
    return await this.loading.dismiss().then((response) => {
      console.log('Loader closed!', response);
    }).catch((err) => {
        console.log('Error occured : ', err);
    });
  }



  alertMessage(msg: string){

    this.alert.create({
      header:'Message',
      subHeader: 'Withdraw',
      message: msg,
      buttons: [
        {
          text: 'Close',
          handler: () => {

            if(this.debugMode===true){
              console.log('Clicked Close');
            }

          }
        }
      ]
    })
    .then(alertEL=> {
      alertEL.present();
    });

  }

  error(){



    localStorage.setItem('et','Oops.. Unexpected error occurred.');
    localStorage.setItem('ed','Please restart your browser or check your internet connection to fix the issue.');
    this.router.navigateByUrl('/kicked');

   }


  withdraw(jwt: string, amount: number, description: string, token32: string, oneTimeUseCode: any){


    this.isLoading=true;
    /*
    ============================================
    2. Input Validation
    ============================================
    */


    /*
    ============================================
    3. Present Loading Modal
    ============================================
    */

    this.showLoader();

    /*
    ============================================
    4. Set Property Settings
    ============================================
    */
    this.errorMsg='';
    this.httpTimeout = this.sysconf.httpTimeout;
    this.httpRetry  = this.sysconf.httpRetry;
    this.debugMode  = this.sysconf.debugMode;


    /*
    ============================================
    5. Http Request Params
    ============================================
    */

    const appId = this.sysconf.appId;
    //const timestamp = Date.now();
    const timestamp = oneTimeUseCode;//Date.now();

    const md5 = new Md5();
    const hashStr = md5.appendStr(`${appId+timestamp+(amount.toString())+description+jwt}`).end();

     /*
    ============================================
    6. Send Request
    ============================================
     email: `${email}`,
      password: `${password}`,
       params: new HttpParams().set('key', `${jwt}`),
      headers: new HttpHeaders().set('Authorization', hashStr.toString()).set('User-Agent', this.sysconf.appUserAgent)
    */
      return this.http.post<WithdrawalResponse>(`${this.sysconf.gatewayDomain+ENDPOINT.url}`,
      {
        token32: `${token32}`,
        appId:`${appId}`,
        amount:`${amount}`,
        description:`${description}`,
        hash:hashStr.toString(),
        timestamp:timestamp.toString()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization','Bearer '+`${jwt}`)
      }
      ).pipe(
        timeout(this.httpTimeout),
        retry(this.httpRetry),
        catchError(error => {

           /*
          ============================================
          Catch Error and Dismiss Loading
          ============================================
          */

          this.errorMsg = error.message;
          if(this.debugMode===true){
            console.log('Catch errorMsg '+ this.errorMsg);
          }




           /*
          ============================================
          Commented this due to duplicate alert
          ============================================
          */


          return of(
            {
              status: 'error',
              message: 'Unexpected Error'
            }
          );

        }),

        switchMap(res => {

          /*
          ============================================
          Response Received
          ============================================
          */

          if(this.debugMode===true){
            console.log('switchMap '+JSON.stringify(res));
          }

          /*
          ============================================
          Show Alert if response status = failed
          ============================================
          */

          /*
          ============================================
          Dismiss Loading
          ============================================
          */

          this.hideLoader();
          return of(res);

        }),

        finalize(() => {

          /*
          ============================================
          Http Finalize and Dismiss Loading
          ============================================
          */

          this.hideLoader();
          if(this.debugMode===true){
            console.log('finalize');
          }

        }));//this.http


    }//withdraw





}
